import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { FullPageLoader, Loader } from 'panamera-react-ui';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

import css from './LocationAutocomplete.APP_TARGET.scss';
import pick from 'lodash/pick';
import { connect } from 'react-redux';
import {
    selectedLocationSelector,
    suggestionsSelector,
    selectedLocationNameSelector,
    isSelectedLocationGPSSelector as isSelectedLocationGPSReduxSelector
} from 'Selectors/location';
import { get as getRecentLocations } from 'Helpers/recentLocations';
import { syncLocationDataInClevertap } from 'Helpers/cleverTap';
import { getSuggestions, updateGPSStatus } from 'Actions/locations';
import { injectIntl } from 'react-intl';
import Auth from '../../../Auth';
import withTrack from 'HOCs/withTrack/withTrack';
import withConfig from 'HOCs/withConfig/withConfig';
import withPopularLocations from 'HOCs/withPopularLocations/withPopularLocations';
import PopularLocations from '../LocationSection/PopularLocations';
import RecentLocations from '../LocationSection/RecentLocations';
import LocationSuggestions from '../LocationSuggestions/LocationSuggestions';
import CurrentLocation from '../LocationSection/CurrentLocation';
import TooltipMessage from '../TooltipMessage/TooltipMessage';
import { LOCATION_START } from 'Constants/tracking';
import { getGeolocationPosition } from 'Actions/categoryBrowsing';
import GpsBlockedModalMessage from 'Components/PermissionModals/GpsBlocked/GpsBlocked';
import { noop } from 'Helpers/function';
import { isMobile } from 'Helpers/devices';
import Icon from 'Components/ThematicIcon/ThematicIcon';
import { THEMES } from 'Constants/bundles';
import { CONFIG } from 'Constants/config';

export const WAIT_INTERVAL = 300;

export class LocationAutocomplete extends React.PureComponent {
    static propTypes = {
        getSuggestions: PropTypes.func,
        suggestions: PropTypes.func,
        selectedLocation: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }),
        popularLocations: PropTypes.arrayOf(PropTypes.shape({
            addressComponents: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            }))
        })).isRequired,
        config: PropTypes.object,
        trackOrigin: PropTypes.string,
        track: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onLostFocus: PropTypes.func,
        suggestionsClass: PropTypes.string,
        defaultFocus: PropTypes.bool,
        intl: PropTypes.object.isRequired,
        showLoading: PropTypes.bool,
        visible: PropTypes.bool,
        onLoading: PropTypes.func,
        trackSelectFrom: PropTypes.string.isRequired,
        isSelectedLocationGPSSelector: PropTypes.bool,
        withArrow: PropTypes.bool,
        selectedLocationName: PropTypes.string.isRequired,
        updateGPSStatus: PropTypes.func,
        locIcon: PropTypes.string,
        containerClassName: PropTypes.string,
        headClassName: PropTypes.string,
        defaultOptionsClass: PropTypes.string,
        customSuggestionListClass: PropTypes.string,
        locationOptionClass: PropTypes.string,
        currentLocationClass: PropTypes.string,
        getDefaultLocation: PropTypes.bool,
        trackName: PropTypes.string,
        tracking: PropTypes.object,
        showBlankSearch: PropTypes.bool
    }

    static defaultProps = {
        config: {},
        getSuggestions: noop,
        suggestions: noop,
        selectedLocation: {},
        trackOrigin: '',
        onChange: noop,
        onFocus: noop,
        onLostFocus: noop,
        suggestionsClass: '',
        defaultFocus: false,
        showLoading: true,
        visible: false,
        onLoading: noop,
        onClickOutside: noop,
        withArrow: false,
        updateGPSStatus: noop,
        headClassName: '',
        defaultOptionsClass: '',
        customSuggestionListClass: '',
        locationOptionClass: '',
        currentLocationClass: '',
        getDefaultLocation: false,
        showBlankSearch: false
    }

    constructor(props) {
        super(props);
        this.state = {
            isTyping: false,
            limit: 5,
            types: props.config.get(CONFIG.DEFAULT_CITY, CONFIG.ENABLE_COUNTRY_IN_AUTOCOMPLETE) ? '' : 'city,state,neighbourhood',
            input: (props.showBlankSearch || (!props.getDefaultLocation && isMobile)) ? '' : props.selectedLocationName,
            loggedUser: Auth.getUser(),
            isLoading: false,
            showError: false,
            showGpsBlockedMessage: false
        };
        this.translations = {
            currentLocation: { id: 'use_current_location' },
            searchLocationHint: { id: 'searchLocationHint' },
            locationErrorTooltip: { id: 'locationErrorTooltip' },
            locationErrorTooltipTry: { id: 'locationErrorTooltipTry' }
        };
        this.inputContainer = null;
        this.isMXCL = props.config.get('olxAutos', 'isMXCL');
        this.theme = props.config.get('theme', 'id');
    }

    componentDidMount() {
        if (this.inputContainer && this.props.defaultFocus) {
            this.focusInputContainer();
        }
        this.timer = null;
        this.props.updateGPSStatus();
    }

    componentDidUpdate = prevProps => {
        const { selectedLocation, isSelectedLocationGPSSelector, selectedLocationName } = this.props;

        if (selectedLocation.id !== prevProps.selectedLocation.id
            || isSelectedLocationGPSSelector !== prevProps.isSelectedLocationGPSSelector) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ input: selectedLocationName });
        }
    }

    focusInputContainer = () => {
        if (this.inputContainer) {
            this.inputContainer.select();
            this.inputContainer.click();
        }
    }

    handleCloseTooltip = () => this.setState({ showError: false });

    handleLoading = isLoading => {
        this.setState({ isLoading });
        this.props.onLoading(isLoading);
    }

    handleOnchange = (location, trackProps) => {
        if (location) {
            this.setState({
                isTyping: false
            }, () => this.props.onChange(location, trackProps));
            syncLocationDataInClevertap(location);
        }
        else {
            this.handleGpsMessage(trackProps);
        }
    }

    handleOnFocus = () => {
        this.props.onFocus('location');
        this.focusInputContainer();
        this.props.track(this.props.trackName || LOCATION_START, {
            origin: this.props.trackOrigin,
            select_from: this.props.trackSelectFrom,
            place_selected_id: this.props.selectedLocation.id,
            ...this.props.tracking
        });
    }

    handleOnFocusIcon = () => this.focusInputContainer();

    handleChangeValue = event => {
        clearTimeout(this.timer);
        const input = event.currentTarget.value;

        if (input.trim().length > 0) {
            this.setState({ input, isTyping: true }, () => {
                if (input.length > 2) {
                    this.timer = setTimeout(this.handleGetSuggestions, WAIT_INTERVAL);
                }
            });
        }
        else {
            this.setState({ input: '', isTyping: false });
        }
    }

    handleGetSuggestions = () => this.props.getSuggestions(pick(this.state, 'input', 'limit', 'types'));

    handleClearInput = () => {
        this.setState({ input: '', isTyping: false });
        this.focusInputContainer();
    }

    handleTryAgain = () => {
        this.handleCloseTooltip();
        this.focusInputContainer();
    }

    handleGpsMessage = trackProps => {
        getGeolocationPosition()
            .then(() => this.handleCloseGpsMessage())
            .catch(() => {
                if (isMobile) {
                    this.props.onChange(this.props.selectedLocation, trackProps);
                }
                else {
                    this.setState({ showGpsBlockedMessage: true });
                }
            });
    };

    handleCloseGpsMessage = () => this.setState({ showGpsBlockedMessage: false }, this.handleOnFocusIcon);

    render() {
        const suggestions = this.props.suggestions(pick(this.state, 'input', 'limit', 'types')) || [];
        const { visible, popularLocations, config, selectedLocation, suggestionsClass, intl, withArrow } = this.props;
        const { isTyping, input, showError, isLoading, showGpsBlockedMessage } = this.state;
        const recentLocations = getRecentLocations();
        const tooltipMessage = (
            <React.Fragment>
                <span>{intl.formatMessage(this.translations.locationErrorTooltip)} </span>
                <span className={ css.tryAgain } onClick={ this.handleTryAgain }>
                    {intl.formatMessage(this.translations.locationErrorTooltipTry)}
                </span>
            </React.Fragment>
        );
        const arrow = withArrow ? (
            <span className={ classNames(css.arrowIcon, { [css.arrowIconUp]: visible }) }>
                <NewIconButton
                    icon="arrowDown"
                    animation={ false }
                    onClick={ visible ? this.props.onLostFocus : this.handleOnFocus }
                    theme={ this.theme }
                />
            </span>
        ) : null;

        return (
            <div className={ classNames(css.locationAutocomplete, this.props.headClassName) }>
                <div data-aut-id="locationBox" className={ classNames(css.containerInput, { [css.thinBorder]: this.isMXCL || this.theme === THEMES.LETGO }, this.props.containerClassName) } ref={ el => (this.containerTooltip = el) }>
                    {isLoading
                        ? <Loader className={ css.iconLoader } containerSize="32px" />
                        : <Icon
                            icon={ this.props.locIcon || 'search' }
                            size={ 25 }
                        />
                    }
                    <input
                        className={ css.inputLocation }
                        value={ input }
                        placeholder={ intl.formatMessage(this.translations.searchLocationHint) }
                        onChange={ this.handleChangeValue }
                        onFocus={ this.handleOnFocus }
                        ref={ element => {
                            this.inputContainer = element;
                        } }
                    />
                    {
                        isTyping
                            ? <span className={ css.clearIcon }>
                                <NewIconButton
                                    icon="cross"
                                    color="blackText"
                                    animation={ false }
                                    onClick={ this.handleClearInput }
                                />
                            </span>
                            : arrow
                    }
                    {showError && <TooltipMessage
                        message={ tooltipMessage }
                        elementNode={ this.containerTooltip }
                        onClose={ this.handleCloseTooltip } />
                    }
                </div>
                {visible && ((suggestions && !!suggestions.length) || !isTyping) && (
                    <div className={ classNames(css.wrapperOptions, suggestionsClass) }>
                        {suggestions && !!suggestions.length
                            && <LocationSuggestions
                                suggestions={ suggestions }
                                onChange={ this.handleOnchange }
                                searchTerm={ input }
                                suggestionsListClass={ css.suggestionsOptions }
                                customSuggestionListClass={ this.props.customSuggestionListClass }
                                locationOptionClass={ this.props.locationOptionClass }
                            />
                        }
                        {!isTyping
                            && <div className={ classNames(css.defaultOptions, this.props.defaultOptionsClass) }>
                                <CurrentLocation
                                    onChange={ this.handleOnchange }
                                    onLoading={ this.handleLoading }
                                    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                                    classes={ { currentLocation: this.props.currentLocationClass, locationOption: this.props.locationOptionClass } } />
                                <RecentLocations
                                    selectedLocation={ selectedLocation }
                                    recentLocations={ recentLocations }
                                    onChange={ this.handleOnchange } />
                                <PopularLocations
                                    popularLocations={ popularLocations }
                                    maxLocations={ config.get('searchHeader', 'maxPopularLocations') }
                                    onChange={ this.handleOnchange } />
                            </div>
                        }
                    </div>
                )}
                {isLoading && this.props.showLoading
                    && <FullPageLoader
                        className={ css.fullPageLoader }
                        text={ intl.formatMessage({ id: 'loadingAdsToRelocate' }) } />
                }
                {showGpsBlockedMessage && <GpsBlockedModalMessage onClose={ this.handleCloseGpsMessage } />}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    suggestions: params => suggestionsSelector(state, params),
    trackOrigin: state.track.origin,
    selectedLocation: selectedLocationSelector(state),
    isSelectedLocationGPSSelector: isSelectedLocationGPSReduxSelector(state),
    selectedLocationName: selectedLocationNameSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
    getSuggestions: params => dispatch(getSuggestions(params)),
    updateGPSStatus: () => dispatch(updateGPSStatus())
});

export default compose(
    withTrack,
    withConfig,
    withPopularLocations,
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(LocationAutocomplete);
